table {
    table-layout: fixed;
}

td, th {
    width: auto;
}

td.min, th.min {
    width: 124px;
    white-space: nowrap;
}

td.minSmall, th.minSmall {
    width: 50px;
    white-space: nowrap;
}

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }

.material-icons.green { color: #27AE60; }

.material-icons.red { color: #db4437; }
